<template>
  <section class="m-review">
    <div class="m-review__attached-photos" v-if="review.image && review.image.length > 0">
      <!-- TODO configure url -->
      <!-- TODO handle more than one photo -->
      <SfImage
        class="m-review__attached-photo"
        :src="'//cdn.stamped.io/uploads/photos/' + review.image[0]"
        :alt="$t('Photo attached to the review')"
      />
    </div>
    <SfLink :link="review.product.url" class="m-review__link">
      <SfImage
        class="m-review__product-thumbnail"
        :src="review.product.thumbnail"
        :alt="$t('Photo attached to the review')"
        v-if="shouldReferToProduct"
      />
      <span v-if="shouldReferToProduct" class="m-review__product-name">{{ sanitizeText(review.product.name) }}</span>
    </SfLink>
    <div class="m-review__text-info">
      <div class="m-review__header">
        <h3 class="m-review__author">
          {{ sanitizeText(review.author) }}
        </h3>
        <div class="m-review__is-verified-section" v-if="review.isVerified">
          <SfIcon class="m-review__is-verified-icon">
            <svg viewbox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.4544 1.14319L12.7481 2.62068L14.6831 1.79943C14.9962 1.66724 15.3581 1.83786 15.4575 2.1613L16.0481 4.06536L18.1472 4.10286C18.4866 4.10849 18.7518 4.40941 18.7097 4.74693L18.4762 6.7288L20.3794 7.61661C20.6672 7.75161 20.7919 8.09474 20.6569 8.38254C20.3972 8.91974 19.9275 9.61254 19.6097 10.146L20.9878 11.7332C21.21 11.9901 21.1603 12.3876 20.8837 12.5826L19.2553 13.7329L19.8684 15.742C19.9678 16.0701 19.7615 16.4085 19.4278 16.4741L17.475 16.8632L17.2191 18.9473C17.175 19.3045 16.8178 19.5351 16.4765 19.4301L14.5725 18.9979L13.4906 20.797C13.3153 21.0895 12.9234 21.1673 12.6506 20.9629L11.0512 19.7694L9.3309 20.9732C9.04871 21.1701 8.66528 21.0782 8.49745 20.781L7.52432 19.0429L5.45994 19.4441C5.10932 19.5107 4.78681 19.2482 4.77462 18.8947L4.59649 16.9447L2.55088 16.4722C2.2162 16.3954 2.02494 16.0476 2.13275 15.7241L2.77401 13.836L1.09681 12.5713C0.80807 12.3529 0.789326 11.9282 1.05369 11.6854L2.37556 10.2557L1.35743 8.41819C1.19056 8.11819 1.32181 7.74225 1.63588 7.60725L3.46494 6.82164L3.28307 4.72819C3.25213 4.36725 3.56056 4.06819 3.91681 4.10287L5.85933 4.12912L6.54371 2.14257C6.65996 1.80508 7.04809 1.65508 7.3612 1.81912L9.14245 2.64131L10.575 1.10663C10.8178 0.846016 11.2322 0.866632 11.4515 1.14319H11.4544ZM6.27084 11.2194C5.5049 10.4534 6.66929 9.2881 7.43616 10.054L10.0162 12.634L14.5339 7.62596C15.2567 6.82251 16.4792 7.92315 15.7564 8.72657L10.6845 14.3479C10.377 14.7257 9.8089 14.7566 9.46296 14.4107L6.26976 11.2184L6.27084 11.2194Z"
              />
            </svg>
          </SfIcon>
          <span class="m-review__is-verified-text">{{ $t('Verified Buyer') }}</span>
        </div>
        <div class="m-review__date desktop-only">
          {{ getReviewDate(review.date) }}
        </div>
      </div>
      <div class="m-review__sub-header">
        <SfRating :score="review.rating" :max="5" class="m-review__rating">
          <template v-slot:icon-positive>
            <SfIcon
              size="1.1rem"
              class="sf-rating__icon custom-icon"
              icon="star"
              color="black"
            />
          </template>
        </SfRating>
        <div class="m-review__voting" v-if="enableVoting">
          <MApprovalCounter
            :initial-value="review.upvotes"
            :is-toggled="upvoted"
            @input="toggleApprovalCounter(review, $event)"
          />
          <MApprovalCounter
            :initial-value="review.downvotes"
            :is-toggled="downvoted"
            @input="toggleDisapprovalCounter(review, $event)"
            :is-like="false"
          />
        </div>
        <div class="m-review__date smartphone-only">
          {{ getReviewDate(review.date) }}
        </div>
      </div>
      <div class="m-review__review">
        <h3 class="m-review__title">
          {{ sanitizeText(review.title) }}
        </h3>
        <div class="m-review__message">
          {{ sanitizeText(review.message) }}
        </div>
      </div>
      <div class="m-review__response" v-if="review.response">
        <div class="m-review__response-header">
          <div class="m-review__response-author">
            {{ websiteName }}
          </div>
          <div class="m-review__response-date">
            {{ getReviewDate(review.responseDate) }}
          </div>
        </div>
        <div class="m-review__response-text">
          {{ sanitizeText(review.response) }}
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import SfIcon from '@storefront-ui/vue/src/components/atoms/SfIcon/SfIcon.vue';
import SfImage from '@storefront-ui/vue/src/components/atoms/SfImage/SfImage.vue';
import SfRating from '@storefront-ui/vue/src/components/atoms/SfRating/SfRating.vue';
import SfLink from '@storefront-ui/vue/src/components/atoms/SfLink/SfLink.vue';

import { mapActions } from 'vuex'
import MApprovalCounter from 'theme/components/molecules/m-approval-counter'
import { getThumbnailForProduct } from '@vue-storefront/core/modules/cart/helpers'

export default {
  name: 'MReview',
  components: {
    SfRating,
    SfIcon,
    MApprovalCounter,
    SfImage,
    SfLink
  },
  props: {
    review: {
      type: Object,
      required: true
    },
    sku: {
      type: String,
      required: true
    },
    enableVoting: {
      type: Boolean,
      default: true
    },
    shouldReferToProduct: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      upvoted: false,
      downvoted: false
    }
  },
  computed: {
    websiteName () {
      return location.hostname
    }
  },
  methods: {
    ...mapActions('stamped-io', {
      upvote: 'upvote',
      downvote: 'downvote',
      vote: 'vote'
    }),
    getThumbnailForProduct (product) {
      return getThumbnailForProduct(product)
    },
    getReviewDate (date) {
      return (date + '').substring(0, 10)
    },
    async toggleApprovalCounter (review, isToggled) {
      try {
        if (isToggled) {
          await this.vote({ sku: this.sku, reviewId: review.id, value: 0 })
        }
        await this.upvote({ sku: this.sku, reviewId: review.id })
      } catch (err) {
        return
      }
      this.upvoted = !this.upvoted
    },
    async toggleDisapprovalCounter (review, isToggled) {
      try {
        if (isToggled) {
          await this.vote({ sku: this.sku, reviewId: review.id, value: 0 })
        }
        await this.downvote({ sku: this.sku, reviewId: review.id })
      } catch (err) {
        return
      }
      this.downvoted = !this.downvoted
    },
    sanitizeText (text) {
      text = text.replace(new RegExp('&#55356;', 'g'), '');

      return text;
    }
  }
}
</script>
<style lang="scss">
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
.sf-link {
  @include for-mobile {
    text-decoration: none;
  }
}
.m-review {
  display: flex;
  border-top: 1px solid var(--c-light);
  padding: var(--spacer-base) 0;
  &:last-child {
    border-bottom: 1px solid var(--c-gray-variant);
  }

  @include for-mobile {
    max-width: 100%;
  }

  &__text-info {
    width: 100%;
  }

  &__message {
    margin-bottom: var(--spacer-xs);
  }

  &__product-thumbnail {
    align-items: center;
    @include for-mobile {
      display: flex;
      justify-content: center;
    }
  }
  &__product-name {
    text-align: center;
    width: 180px;
    @include for-mobile {
      width: 100%;
      font-size: var(--font-size--lg);
      font-weight: var(--font-weight--bold);
      text-decoration: none;
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
    grid-column: 1;
    grid-row: 1 / 4;
    @include for-mobile {
      flex-direction: column-reverse;
      grid-column: 1;
      grid-row: 1;
    }
  }

  &__header {
    display: flex;
  }

  &__author {
    margin: auto var(--spacer-xs) auto 0;
    @include for-mobile {
      margin:0 ;
      line-height: 21px;
    }
  }

  &__date {
    align-self: flex-end;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
  }

  &__is-verified-section {
    display: flex;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 17px;
    @include for-mobile {
      margin:0 0 0 auto;
      align-items: start;
    }
  }

  &__is-verified-icon {
    padding-top: 1px;
    padding-left: 1px;
    fill: var(--review-verification-badge-color);
  }

  &__is-verified-text {
    color: var(--c-success);
  }

  &__sub-header {
    margin-top: var(--spacer-xs);
    display: flex;
  }

  &__rating {
    align-items: center;
    margin-right: var(--spacer-xs);
  }

  &__voting {
    display: flex;
    align-items: center;
  }

  &__title {
    font-weight: bold;
    margin: var(--spacer-sm) 0 var(--spacer-xs) 0;
  }

  &__response-header {
    color: var(--c-gray);
  }

  &__response-author {
    font-weight: bolder;
    text-transform: uppercase;
    @include for-mobile {
      white-space: nowrap;
    }
  }

  &__response {
    display: flex;
    flex-direction: column;
    border-style: solid;
    border-width: 0 0 0 var(--spacer-xs);
    border-color: var(--c-light-variant);
    padding: var(--spacer-xs) 0 var(--spacer-xs) var(--spacer-xs);
  }

  &__attached-photos {
    min-width: 150px;
    margin-right: var(--spacer-base);
    @include for-mobile {
      min-width: 90px;
      margin-right: var(--spacer-sm);
    }
  }

  &__attached-photo {
    max-width: 150px;
    width: 150px;
    @include for-mobile {
      max-width: 90px;
      width: 90px;
    }
  }
}
</style>
