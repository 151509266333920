<template>
  <div class="m-review-list">
    <a href="#o-reviews" class="m-review-list__pagination-wrapper">
      <MPagination
        :key="totalPages"
        v-show="totalPages > 1"
        :current="currentPage"
        :visible="visible"
        :total="totalPages"
        class="m-review-list__pagination"
        page-param-name="reviewPage"
        emit-only
        @click="handlePageChange"
      />
    </a>
    <div v-show="loading" class="m-review-list__spinner">
      <ASpinner :size="48" />
    </div>
    <div v-show="error" class="m-review-list__spinner">
      <p>
        {{ $t('Cannot get reviews list, please try again.') }}
      </p>
      <SfButton class="sf-button--primary" @click="handlePageChange(currentPage, { forceFetch: true })">
        {{ $t('Try again') }}
      </SfButton>
    </div>
    <transition-group name="fade" tag="div" class="m-review-list__reviews">
      <MReview
        v-for="review in currentPageReviews"
        v-show="!loading && !error"
        :key="review.id"
        v-if="review"
        :review="review"
        :sku="product.sku"
        :enable-voting="enableVoting"
        :should-refer-to-product="shouldReviewsReferProducts"
      />
    </transition-group>
    <a href="#o-reviews" class="m-review-list__pagination-wrapper">
      <MPagination
        :key="totalPages"
        v-show="totalPages > 1"
        :current="currentPage"
        :visible="visible"
        :total="totalPages"
        class="m-review-list__pagination"
        page-param-name="reviewPage"
        emit-only
        @click="handlePageChange"
      />
    </a>
  </div>
</template>

<script>
import config from 'config'
import { mapActions } from 'vuex'
import { getThumbnailForProduct } from '@vue-storefront/core/modules/cart/helpers'
import SfButton from '@storefront-ui/vue/src/components/atoms/SfButton/SfButton.vue';
import ASpinner from '~/theme/components/atoms/a-spinner'
import MReview from 'theme/components/molecules/m-review'
import MPagination from 'theme/components/molecules/m-pagination'

export default {
  name: 'MReviewList',
  components: {
    ASpinner,
    SfButton,
    MPagination,
    MReview
  },
  props: {
    reviews: {
      type: Array,
      default: () => []
    },
    visible: {
      type: Number,
      default: 5
    },
    product: {
      type: Object,
      required: true
    },
    totalPages: {
      type: Number,
      default: 0
    },
    sortingMethod: {
      type: String,
      default: 'none'
    },
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    },
    shouldReviewsReferProducts: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentPage: 1
    }
  },
  computed: {
    currentPageReviews () {
      const perPage = config.stampedIo.reviewsPerPage
      const startIndex = (this.currentPage - 1) * perPage
      const endIndex = startIndex + perPage
      return this.reviews.slice(startIndex, endIndex)
    }
  },
  created () {
    this.enableVoting = config.stampedIo.showReviewVoteApproval
  },
  watch: {
    sortingMethod: {
      handler (newVal, oldVal) {
        this.setCurrentPage(1)
        // this.handlePageChange(1, { force: !!oldVal || newVal !== oldVal })
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('stamped-io', {
      upvote: 'upvote',
      downvote: 'downvote'
    }),
    setCurrentPage (newPage) {
      this.currentPage = newPage
    },
    toggleApprovalCounter (review, isToggled) {
      this.upvote({ sku: this.product.sku, reviewId: review.id })
    },
    toggleDisapprovalCounter (review, isToggled) {
      this.downvote({ sku: this.product.sku, reviewId: review.id })
    },
    getThumbnailForProduct (product) {
      return getThumbnailForProduct(product)
    },
    async handlePageChange (page, options = {}) {
      this.$emit('page-change', { page, options, sortingMethod: this.sortingMethod, currentPage: this.currentPage })
      this.setCurrentPage(page)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";

.m-review-list {
  &__spinner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--spacer-base) var(--spacer-lg);
    width: auto;
    @include for-desktop {
      width: 25%;
    }
  }
  &__pagination-wrapper {
    --pagination-item-margin: var(--spacer-2xs);
    pointer-events: none;
    display: flex;
    justify-content: center;
    height: 1rem;
    padding: var(--spacer-base) 0;
    @include for-desktop {
      --pagination-item-margin: var(--spacer-sm);
    }
  }
  &__pagination {
    pointer-events: auto;
  }
  &__reviews {
    display: flex;
    margin: var(--spacer-sm) 0;
    flex-direction: column;
  }
}
</style>
