<template>
  <div class="m-approval-counter" @click="toggle">
    <AIcon
      :class="{ 'm-approval-counter--toggled': isToggled }"
      icon="like"
      v-if="isLike"
    />
    <AIcon
      :class="{ 'm-approval-counter--toggled': isToggled }"
      icon="dislike"
      v-else
    />
    <div class="m-approval-counter__value">
      {{ counterValue }}
    </div>
  </div>
</template>

<script>
import AIcon from '~/theme/components/atoms/a-icon'
import { mapActions } from 'vuex';

export default {
  name: 'MApprovalCounter',
  components: { AIcon },
  props: {
    isLike: {
      type: Boolean,
      default: true
    },
    initialValue: {
      type: Number,
      default: 0
    },
    isToggled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    counterValue () {
      return this.initialValue + this.isToggled
    }
  },
  methods: {
    ...mapActions('stamped-io', {
      upvote: 'upvote',
      downvote: 'downvote'
    }),
    toggle () {
      this.$emit('input', this.isToggled)
    }
  }
};
</script>

<style lang="scss" scoped>
.m-approval-counter {
  display: flex;
  align-items: center;
  cursor: pointer;
  &__value {
    margin-left: 5px;
    margin-right: 5px;
  }
  &--toggled {
    fill: var(--c-success);
  }
}
</style>
